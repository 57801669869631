import React from "react";
import { useNavigate } from "react-router-dom";
const Error404 = () => {
  const navigate = useNavigate();
  return (
    <div >   
    <div style={{marginTop: "10%"}}>
    <h1 style={{textAlign: "center"}}>
            404 Page Not Found
          </h1>
          <div style={{textAlign: "center"}}>
            <button onClick={() => navigate("/")}>
            Go Home
          </button>
          </div>
          
    </div>
     </div>
  );
};

export default Error404;
